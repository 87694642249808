@tailwind base;
@tailwind components;
@tailwind utilities;

body{ 
  background: #faf2e1;
}

.rmdp-container {
  @apply w-full
}

/* nomi giorni della settimana */
.rmdp-week-day {
  @apply !text-black !text-opacity-50 uppercase !text-[12px];
}
/* nomi mesi */
.rmdp-header-values {
  @apply !text-blue !font-bold;
}

.ep-arrow {
  @apply hidden
}
/* date selezionate */
.rmdp-range {
  @apply !bg-blue bg-opacity-20 !shadow-none !text-black;

  &.start {
    @apply !bg-blue !rounded-l-md !text-white;
  }
  &.end {
  @apply !bg-blue !rounded-r-md !text-white;
  }
}

/* data in hover */
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  @apply !bg-transparent !text-white !rounded-md;
}

.rmdp-day-picker,
.rmdp-top-class {
  display:  block !important;
}

.mobile .rmdp-top-class {
  @apply w-screen;
}

.rmdp-container > div:nth-child(3) {
  position: fixed !important;
  bottom: 0px !important;
  top: auto !important;
  transform: none !important;
}

.mobile .rmdp-day {
  width: 100% !important;
}

.rmdp-action-buttons {
  @apply relative top-[-83px];
}

button {
  @apply !text-white text-lg !bg-blue !rounded-lg !py-3 !px-10
}

.rmdp-top-class {
  @apply bg-[#fffbf0];
}

.rmdp-calendar {
  @apply !px-3;
}

.rmdp-day-picker {
  @apply !px-0;
}